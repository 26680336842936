
/**
 * Easy selector helper function
 */
export const select = (el, all = false) => {
    el = el.trim();
    if (all) {
        return [...document.querySelectorAll(el)];
    } else {
        return document.querySelector(el);
    }
};

/**
 * Easy event listener function
 */
export const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
        if (all) {
            selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
            selectEl.addEventListener(type, listener);
        }
    }
};

/**
 * Easy on scroll event listener
 */
export const onscroll = (el, listener) => {
    el.addEventListener("scroll", listener);
};


/**
 * Scrolls to an element with header offset
 */
export const scrollTo = (el) => {
    let header = select("#header");
    let offset = header.offsetHeight;
    let elementPos = select(el).offsetTop;
    if (!header.classList.contains('header-scrolled')) {
        offset -= 16
    }
    window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
    })
};

export const handleNavbar = () => {
    let navbarLinks = select("#navbar .scrollto", true);
    const navbarLinksActive = () => {
        let position = window.scrollY + 200;
        navbarLinks.forEach((navbarLink) => {
            if (!navbarLink.hash) return;
            let section = select(navbarLink.hash);
            if (!section) return;
            if (
                position >= section.offsetTop &&
                position <= section.offsetTop + section.offsetHeight
            ) {
                navbarLink.classList.add("active");
            } else {
                navbarLink.classList.remove("active");
            }
        });
    };
    window.addEventListener("load", navbarLinksActive);
    onscroll(document, navbarLinksActive);
}

