import React from "react"
import "./BackToTop.css";

export default function BackTotTop() {

    function backToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    return (
        <div>
            <button
                className="back-to-top d-flex align-items-center justify-content-center"
                onClick={backToTop}>
                <i className="bx bx-up-arrow-alt"></i>
            </button>
        </div>
    );
}
