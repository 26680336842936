import React from "react";
import { Link } from "react-router-dom";
import { handleNavbar, onscroll, scrollTo, select } from "../../shared/utils";

import "./Header.css";

class Header extends React.Component {

    componentDidMount() {
        window.removeEventListener('load', this.handleLoad)
        this.handleHeaderScroll();
        this.handleNavbarLinks();

    }

    handleHeaderScroll() {
        /**
         * Header fixed top on scroll
         */
        let selectHeader = select('#header')
        if (selectHeader) {
            // let headerOffset = selectHeader.offsetTop
            // let nextElement = selectHeader.nextElementSibling
            const headerFixed = () => {
                if ((window.scrollY) >= 15) {
                    selectHeader.classList.add('header-scrolled')
                    // nextElement.classList.add('scrolled-offset')
                } else {
                    selectHeader.classList.remove('header-scrolled')
                    // nextElement.classList.remove('scrolled-offset')
                }
            }
            window.addEventListener('load', headerFixed)
            onscroll(document, headerFixed)
        }
    }

    handleNavbarLinks() {
        handleNavbar();
        /**
         * Hide mobile nav on same-page/hash links
         */
        select('#navbar .scrollto', true).forEach(navbarlink => {

            if (!navbarlink.hash) return;

            let section = select(navbarlink.hash);
            if (!section) return;

            navbarlink.addEventListener('click', () => {
                if (select('.mobile-nav-active')) {
                    this.handleToggleClick();
                }
            });
        });

    }


    handleToggleClick(e) {
        select('#navbar').classList.toggle('navbar-mobile');
        const el = document.getElementById('toggleButton');
        el.innerHTML = el.innerText === 'menu' ? 'close' : 'menu';
    }

    handleLinkClick(event) {
        if (select(event.target.hash)) {
            event.preventDefault();
            let navbar = select("#navbar");
            if (navbar.classList.contains("navbar-mobile")) {
                navbar.classList.remove("navbar-mobile");
                const el = document.getElementById('toggleButton');
                el.innerHTML = el.innerText === 'menu' ? 'close' : 'menu';
            }
            scrollTo(event.target.hash);
        }
    }

    downloadResume(event) {
        window.open('assets/resume/resume.pdf', '_blank');
    }

    render() {
        return (
            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">

                    <div className="logo">
                        <h1><Link to="/"><span>!</span>&lt; <span> |</span>&gt;</Link></h1>
                        {/* Uncomment below if you prefer to use an image logo */}
                        {/* <Link to="index.html">
                            <img src="assets/logos/logo.png" alt="Logo" className="img-fluid" />
                            <br />
                            <h4>
                                Kishan Panchal
                            </h4>
                        </Link> */}
                    </div>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li data-aos="fade-down" data-aos-delay="100"><Link className="nav-link link scrollto active" onClick={(e) => this.handleLinkClick(e)} to="/#hero">Home</Link></li>
                            <li data-aos="fade-down" data-aos-delay="200"><Link className="nav-link link scrollto" onClick={(e) => this.handleLinkClick(e)} to="/#about">About</Link></li>
                            <li data-aos="fade-down" data-aos-delay="300"><Link className="nav-link link scrollto" onClick={(e) => this.handleLinkClick(e)} to="/#work">Work</Link></li>
                            <li data-aos="fade-down" data-aos-delay="300"><Link className="nav-link link" onClick={(e) => this.downloadResume(e)} to="">Resume</Link></li>
                            {/* <li><Link className="nav-link link scrollto" onClick={(e) => this.handleLinkClick(e)} to="/#contact">Contact</Link></li> */}
                        </ul>
                        <i id="toggleButton" className="material-icons-round mobile-nav-toggle" onClick={(e) => this.handleToggleClick(e)}>menu</i>
                    </nav>

                </div>
            </header>
        );
    }
};

export default Header;
