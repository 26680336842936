import React from "react";
import "./Services.css";
import demos from "./demos.json";
import projects from "./projects.json";


export default function Services() {
    return (
        <div>
            <section id="work" className="work">
                <h2 className="section-title" data-aos="fade-up">Something I’ve built</h2>
                <div className="container">
                    <ul className="project-list">
                        {projects.map((item, i) => {
                            return (
                                <li key={i} className="project-item" data-aos="fade-up" data-aos-delay={i * 100}>
                                    <div className="project-content">
                                        <div>
                                            <p className="project-overline">Featured Project</p>
                                            <h3 className="project-title"><a className="link" href={item?.launch_url} rel="noopener noreferrer"
                                                target="_blank">{item.title}</a></h3>
                                            <div className="project-description">
                                                <p dangerouslySetInnerHTML={{
                                                    __html: item.description
                                                }}></p>
                                            </div>
                                            <ul className="project-tech-list">
                                                {item.tech_list.map((t, i) => {
                                                    return (<li key={'t' + i}>{t}</li>);
                                                })}
                                            </ul>
                                            <div className="project-links">
                                                {
                                                    item?.code_url &&
                                                    <a href={item.code_url} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                                                        <i className="material-icons-round">code</i>
                                                    </a>
                                                }
                                                {
                                                    item?.playstore_url &&
                                                    <a href={item.playstore_url} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                                                        <i className='bx bxl-play-store'></i>
                                                    </a>
                                                }
                                                {
                                                    item?.launch_url &&
                                                    <a href={item.launch_url} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                                                        <i className="material-icons-round">launch</i>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-image" data-aos="zoom-in" data-aos-delay={i * 100}>
                                        <a href={item?.launch_url} rel="noopener noreferrer" target="_blank">
                                            <div className="image-wrapper">
                                                <img src={item?.thumbnail} alt={item?.title + '\'s thumbnail'} ></img>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>
            <section id="demos" className="work">
                <h2 className="section-subtitle" data-aos="fade-up">Other Noteworthy Demos</h2>
                <div className="demos-grid">
                    {demos.map((item, i) => {
                        return (
                            <div className="grid-item" key={i} data-aos="fade-up" data-aos-delay={i * 100}>
                                <div className="grid-header">
                                    <div className="grid-top">
                                        <div className="grid-icon">
                                            <i className="material-icons-outlined">folder</i>
                                        </div>
                                        <div className="demo-links">
                                            {
                                                item?.code_url &&
                                                <a href={item.code_url} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                                                    <i className="material-icons-round">code</i>
                                                </a>
                                            }
                                            {
                                                item?.launch_url &&
                                                <a href={item.launch_url} aria-label="External Link" className="external" target="_blank" rel="noopener noreferrer">
                                                    <i className="material-icons-round">launch</i>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                    <h3 className="demo-title"><a className="link" href={item?.launch_url || item?.code_url}>{item.title}</a></h3>
                                    <p className="demo-description">{item?.description}</p>
                                </div>
                                <div className="grid-footer">
                                    <ul className="project-tech-list">
                                        {item.tech_list.map((t, i) => {
                                            return (<li key={'t' + i}>{t}</li>);
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        </div>

    );
}
