import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { handleNavbar, select } from './../../shared/utils';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const navLinks = select('.scrollto', true);
        navLinks.forEach(element => {
            element.classList.remove('active');
        });
        handleNavbar();
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
