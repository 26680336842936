import React from "react";
import { Route, Routes } from "react-router-dom";
import { select, onscroll, scrollTo } from './shared/utils';
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import BackToTop from "./components/BackToTop/BackToTop";
import Hero from './components/Hero/Hero';
import Footer from './components/Footer/Footer';

import "./App.css";

import AOS from 'aos';
import "aos/dist/aos.css";

class App extends React.Component {
    componentDidMount() {
        const preloader = document.querySelector('#preloader');
        setTimeout(() => {
            if (preloader) {
                preloader.remove();
            }
        }, 100);
        let button = select(".back-to-top");
        if (button) {
            const toggleBackToTop = () => {
                if (window.scrollY > 100) {
                    button.classList.add("active");
                } else {
                    button.classList.remove("active");
                }
            };
            window.addEventListener("load", toggleBackToTop);
            onscroll(document, toggleBackToTop);
        }

        AOS.init({
            duration: 500,
            disable: 'mobile',
            once: false,
            // mirror: true
        });

        window.addEventListener("load", () => {
            if (window.location.hash) {
                if (select(window.location.hash)) {
                    scrollTo(window.location.hash);
                }
            }
        });
    }

    render() {
        return (
            <div className="app">
                <Header></Header>
                <div orientation="left" className="left-div" data-aos="fade-right">
                    <ul className="social-links">
                        <li data-aos="fade-up" data-aos-delay="100">
                            <a href="https://github.com/coderman401" aria-label="GitHub" target="_blank" rel="noreferrer">
                                <i className="bx bxl-github"></i>
                            </a>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="200">
                            <a href="https://www.instagram.com/coderman401" aria-label="Instagram" target="_blank" rel="noreferrer">
                                <i className="bx bxl-instagram"></i>
                            </a>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="300">
                            <a href="https://twitter.com/coderman401" aria-label="Twitter" target="_blank" rel="noreferrer">
                                <i className="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="400">
                            <a href="https://www.linkedin.com/in/kishan-panchal-728299251/" aria-label="Linkedin" target="_blank" rel="noreferrer">
                                <i className="bx bxl-linkedin-square"></i>
                            </a>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="500">
                            <a href="https://codepen.io/coderman401" aria-label="Codepen" target="_blank" rel="noreferrer">
                                <i className="bx bxl-codepen"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <Hero></Hero>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
                <Footer></Footer>
                <BackToTop></BackToTop>
            </div>
        );

    }
}

export default App;
