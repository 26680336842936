import React from "react";
import "./Footer.css"

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="credits">
                    Designed & Built by <strong><a className="link" href="https://github.com/coderman401">Kishan Panchal</a></strong>
                </div>
                <div className="copyright">
                    &copy; Copyright <strong><a className="link" href="https://github.com/coderman401">Kishan Panchal</a></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    );
};

export default Footer;
