import React from "react";
import About from "../About/About";
import Services from "../Services/Services";

import "./Home.css";

export default class Home extends React.Component {
    render() {
        return (
            <main className="main" id="main">
                <About></About>
                <Services></Services>
                {/* <About></About> */}
            </main>
        );
    }
}
