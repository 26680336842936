
import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTo, select } from '../../shared/utils';
import "./Hero.css";
import hero from "./hero.json";

export default function Hero() {
    function handleLinkClick(event) {
        if (select(event.target.hash)) {
            event.preventDefault();

            let navbar = select("#navbar");
            if (navbar.classList.contains("navbar-mobile")) {
                navbar.classList.remove("navbar-mobile");
                const el = document.getElementById('toggleButton');
                el.innerHTML = el.innerText === 'menu' ? 'close' : 'menu';
            }
            scrollTo(event.target.hash);
        }
    }

    return (
        <section id="hero">
            <div className="hero-wrapper">
                <div className="container">
                    <h1 className="heading" data-aos="fade-up">
                        <em>&lt;Hello World /&gt;</em>, my name is</h1>
                    <h2 className="name" data-aos="fade-up" data-aos-delay="100">{hero.name}</h2>
                    {/* <h3 className="tag">I make the two ends meet.</h3> */}
                    <h3 className="tag" data-aos="fade-up" data-aos-delay="200">{hero.tag}</h3>
                    <p className="description" data-aos="fade-up" data-aos-delay="300">{hero.description}</p>
                </div>
                <Link to="/#about" onClick={(e) => handleLinkClick(e)} className="btn-get-started scrollto">
                    <i className="material-icons-round">
                        keyboard_double_arrow_down
                    </i>
                </Link>
            </div>
        </section >
    );
}

