import React from "react";
import './About.css';
import skills from "./skills.json";

export default class About extends React.Component {
    render() {
        const startDate = new Date('2019-07-01');
        const currentDate = new Date();
        const expYear = currentDate.getFullYear() - startDate.getFullYear();
        return (
            <section id="about" className="about">
                <h2 className="section-title" data-aos="fade-up">About Me</h2>
                <div className="container about-wrapper">
                    <div className="content d-flex flex-column justify-content-center" data-aos="fade-up">
                        <div>
                            <p>Hello! My name is Kishan and I find it interesting to solve development challenges, build new things and implement on my imagination. I have been in this field from more than {expYear} years.</p>
                            <p>Here are a few technologies I’ve been working with recently:</p>
                        </div>
                        <ul className="skills-list">
                            {skills.map((s, i) => {
                                return (<li key={i + 1}>{s.name}</li>)
                            })}
                        </ul>
                    </div>
                    <div className="image" data-aos="zoom-in" data-aos-delay="200">
                        <img alt="Kishan Panchal" src="assets/images/me/two.png" />
                    </div>
                </div>
            </section>
        );
    }

}
